<template>
  <GameTitle/>
  <RaisedIconButton top="calc(min(5vw, 5vh))" left="calc(100% - min(15vw, 15vh))" width="calc(min(10vw, 10vh))" height="calc(min(10vw, 10vh))" imgTop="calc(min(1.5vw, 1.5vh))" imgLeft="calc(min(1.5vw, 1.5vh))" imgSide="calc(min(7vw, 7vh))" label="" icon="settings" toScreen="/settings"/>
  <RaisedIconButton top="calc(50% + 7.5vh)" left="calc(50% - min(20vw, 20vh))" width="calc(min(40vw, 40vh))" height="calc(min(15vh, 15vw))" imgTop="calc(min(4vw, 4vh))" imgLeft="calc(100% - min(10vw, 10vh))" imgSide="calc(min(7vw, 7vh))" label="Start" icon="play" toScreen="/playerSelection"/>
  <RaisedIconButton top="calc(75% + 5vh)" left="calc(50% - min(20vw, 20vh))" width="calc(min(40vw, 40vh))" height="calc(min(15vh, 15vw))" imgTop="calc(min(4vw, 4vh))" imgLeft="calc(100% - min(10vw, 10vh))" imgSide="calc(min(7vw, 7vh))" label="Rules" icon="howto" toScreen="/rules"/>
</template>

<script>
import GameTitle from '../home/gameTitle.vue'
import RaisedIconButton from '../global/raisedIconButton.vue'
export default {
  name: 'HomeScreen',
  components: {
    GameTitle,
    RaisedIconButton,
  }
}
</script>
