<template>
    <h1> Settings </h1>
    <RaisedIconButton top="calc(min(5vw, 5vh))" left="calc(min(5vw, 5vh))" width="calc(min(10vw, 10vh))" height="calc(min(10vw, 10vh))" imgTop="calc(min(1.5vw, 1.5vh))" imgLeft="calc(min(1.5vw, 1.5vh))" imgSide="calc(min(7vw, 7vh))" label="" icon="backbutton" toScreen="back"/>
    <table>
        <colgroup>
        <col class="fifty">
        <col class="fifty">
        </colgroup>
        <tr> <th>
    <h2> Minimum length of valid word: </h2>
    </th>
    <th>
    <div class="dropdown">
        <VueMultiselect
        :v-model="selectedMinLength"
        :options="minLengths"
        :searchable="false"
        :close-on-select="true"
        :allow-empty="false"
        @update:model-value="updateMinLength"
        :placeholder="selectedMinLength.toString()"
        :select-label="''"
        />
    </div>
    </th>
        </tr>
        <tr>
            <th>
    <h2> Number of bots in multiplayer: </h2>
    </th>
        <th>
    <div class="dropdown">
        <VueMultiselect
        :v-model="selectedNumberOfBots"
        :options="numberOfBots"
        :searchable="false"
        :close-on-select="true"
        :allow-empty="false"
        @update:model-value="updateNumberOfBots"
        :placeholder="selectedNumberOfBots.toString()"
        :select-label="''"
        />
    </div>
    </th>
        </tr>
        <tr>
            <th>
    <h2> Maximum difficulty in multiplayer: </h2>
    </th>
        <th>
    <div class="dropdown">
        <VueMultiselect
        :v-model="multiplayerDifficulty"
        :options="multiplayerDifficultyOptions"
        :searchable="false"
        :close-on-select="true"
        :allow-empty="false"
        @update:model-value="updateMultiplayerDifficulty"
        :placeholder="multiplayerDifficulty"
        :select-label="''"
        />
    </div>
    </th>
    </tr>
        <tr>
            <th>
    <h2> Allow mixing of difficulty levels in multiplayer: </h2>
    </th>
        <th>
    <div class="dropdown">
        <VueMultiselect
        :v-model="allowMixing"
        :options="mixingOptions"
        :searchable="false"
        :close-on-select="true"
        :allow-empty="false"
        @update:model-value="updateMixing"
        :placeholder="allowMixing"
        :select-label="''"
        />
    </div>
    </th>
        </tr>
        <tr>
            <th>
    <h2> Time in seconds for each player: </h2>
    </th>
        <th>
    <div class="dropdown">
        <VueMultiselect
        :v-model="timeInSeconds"
        :options="timeOptions"
        :searchable="false"
        :close-on-select="true"
        :allow-empty="false"
        @update:model-value="updateTimePerRound"
        :placeholder="timeInSeconds.toString()"
        :select-label="''"
        />
    </div>
    </th>
    </tr>
</table>
</template>

<script>
import RaisedIconButton from '../global/raisedIconButton.vue'
import VueMultiselect from 'vue-multiselect';
import {useSettingsStore} from '@/stores/settingsStore';
export default {
    name: 'SettingsScreen',
    data () {
        return {
        selectedMinLength: 3,
        minLengths: [3, 4, 5, 6, 7, 8],
        selectedNumberOfBots: 2,
        numberOfBots: [2, 3, 4, 5, 6, 7, 8, 9],
        allowMixing: false,
        mixingOptions: ['true', 'false'],
        timeInSeconds: 60,
        timeOptions: [30, 60, 90, 120],
        multiplayerDifficulty: 'Buff',
        multiplayerDifficultyOptions: ['Baby', 'Buff', 'Boor'],
        }
    },
    setup() {
        const settingsStore = useSettingsStore();
        return {settingsStore}
    },
    mounted() {
        console.log(this.selectedMinLength, this.minLengthOfValidWord);
        this.selectedMinLength = this.settingsStore.minLengthOfValidWord;
        this.selectedNumberOfBots = this.settingsStore.numBotsMultiplayer;
        this.allowMixing = this.settingsStore.multiplayerMixed;
        this.timeInSeconds = this.settingsStore.timePerRound;
        this.multiplayerDifficulty = this.multiplayerDifficultyOptions[this.settingsStore.difficultyMultiplayer - 1];
    },
    methods: {
        updateMinLength(value) {
            this.selectedMinLength = value;
            this.settingsStore.minLengthOfValidWord = value;
            console.log('updated minimum length.');
        },
        updateNumberOfBots(value) {
            this.selectedNumberOfBots = value;
            this.settingsStore.numBotsMultiplayer = value;
            console.log("updated num bots.");
        },
        updateMixing(value) {
            this.allowMixing = value;
            this.settingsStore.multiplayerMixed = value === 'true' ? true : false;
            console.log("updated mixing");
        },
        updateTimePerRound(value) {
            this.timeInSeconds = value;
            this.settingsStore.timePerRound = value;
            console.log("updated time per round");
        },
        updateMultiplayerDifficulty(value) {
            this.multiplayerDifficulty = value;
            this.settingsStore.difficultyMultiplayer = this.multiplayerDifficultyOptions.indexOf(value) + 1;
            console.log("updated difficulty");
        }
    },
    components: {
        RaisedIconButton,
        VueMultiselect
    }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.dropdown {
    width: 30% !important;
    margin-left: auto;
    margin-right: auto;
}
:deep(.multiselect__placeholder) {
    color: #354a68 !important;
    font-weight: 500;
    font-size: calc(max(1vw, 1vh));
}
:deep(.multiselect__select) {
    transform: scale(1.5);
}
table {
    position: fixed;
    top: 10%;
    table-layout: fixed;
    width: 80%;
    height: 80%;
    left: 10%;
    text-align: right;
}
.fifty {
    width: 50%;
}
h1 {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: calc(max(2.5vw, 2.5vh));
    font-weight: 600;
    color: #354a68;
}
h2 {
    font-size: calc(max(1.5vw, 1.5vh));
    color: #354a68;
    font-weight: 600;
}
</style>