<template>
    <div class = "outerFrame">
        <div class = "innerFrame">
            <span class = "title">
                Pro-Long
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GameTitle',
    props: {},
}
</script>

<style scoped>
div {
    border-radius: 1vw;
}
.outerFrame {
    position: fixed;
    top: calc(50% - min(30vw, 30vh));
    height: calc(min(30vw, 30vh));
    left: calc(50% - min(35vw, 35vh));
    width: calc(min(70vw, 70vh));
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7), 10px 10px 10px rgba(174, 174, 192, 0.2);
}
.innerFrame {
    display: table;
    position: absolute;
    top: 2vw;
    left: 2vw;
    height: calc(100% - 4vw);
    width: calc(100% - 4vw);
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7) inset, 10px 10px 10px rgba(174, 174, 192, 0.2) inset;
    text-align: center;
}
.title {
    display: table-cell;
    vertical-align: middle;
    font-size: calc(max(6vw, 6vh));
    font-weight: 600;
    color: #354a68;
}
</style>