<template>
    <RaisedIconButton top="calc(min(3vw, 3vh))" left="calc(min(3vw, 3vh))" width="calc(min(10vw, 10vh))" height="calc(min(10vw, 10vh))" imgTop="calc(min(1.5vw, 1.5vh))" imgLeft="calc(min(1.5vw, 1.5vh))" imgSide="calc(min(7vw, 7vh))" label="" icon="backbutton" toScreen="back"/>
    <table><tbody>
    <tr><th><h2 class="subtitle"> For the Pros at making it Long </h2></th></tr>
    <tr><th><h1> ProLong </h1></th></tr>
    <tr><th class="answer">
    <span><i>Players are assembling ‘bridges’ of alphabets one letter at a time - and these bridges collapse as soon as they form valid words. Don’t be the one causing the collapses! </i></span>
    </th></tr>
    <tr><th>
    <h2> The Game Explained: in FAQ style </h2>
    </th></tr>
    <tr><th>
    <span class="question"> Q. What is ProLong? </span>
    </th></tr>
    <tr><th class="answer">
    <span> 	ProLong is a game where players engage in extending word ‘fragments’ to form the longest possible words. Starting with an empty ‘string’, each player suggests an alphabet, in order, under a time limit, and the goal is to keep extending this ‘fragment’ cogently until a valid word is formed. The game ends as soon as <i><u>the next player</u></i> points out a word has been formed (that the fragment has been “completed”).</span>
    </th></tr>
    <tr><th>
    <span class="question"> Q. What's with the underline in the previous question? </span>
    </th></tr>
    <tr><th class="answer">
    <span> That’s the trick part. Since the game only ends when a player points out that a valid word has been made, other players may, deceptively, choose to ‘bluff,’ i.e. say alphabets that do not lead to any valid word, if they think they can confuse the following players into believing the fragment is still extensible.  </span>
    </th></tr>
    <tr><th>
    <span class="question"> Q. How do I play? </span>
    </th></tr>
    <tr><th class="answer">
    <span> <span class="subheading">Player Selection:</span> Choose your competitor(s). </span>
    <img :src="require('@/assets/playerSelection.png')"/>
    </th></tr>
    <tr><th class="answer">
<span><span class="subheading">Game Screen:</span> Type your alphabet. Hit enter. Wait for your next turn.
Hit the ‘Valid’ button if you see a <u>valid word</u>, and the ‘Invalid’ button if you see a bluff. 
The player who needs to move is shown in the top right corner. Your time remaining is shown top-center. Just type to add your alphabet (click in the window if you don’t see letters popping up). You’re allowed to edit your letter before you record it - so it will be shown translucently until you hit ‘enter/return’ on your keyboard, at which point it will get ‘raised’. The game ends after any challenge (invalid or valid), and then the player who wins starts the next round on replay. </span>
    <br><br>
    <span><span class="subheading">Pausing:</span> To pause, press the period ('.') key on your keyboard. </span>
    <img :src="require('@/assets/gameScreen.png')"/>
    </th></tr>
    <tr><th>
    <span class="question"> Q. What's a valid word? What's the corresponding setting? </span>
    </th></tr>
    <tr><th class="answer">
    <span> First: definition of ‘valid word’ - anything in the dictionary (Merriam-Webster’s!) that is not an abbreviation or a proper noun. Basically any word that makes sense as is, and you wouldn’t capitalize it in a sentence. Second: there is one tricky setting you should know about. Minimum length of valid word: you may only challenge the <b>validity</b> of the fragment only when the fragment is at least this many letters long. This is because if no minimum existed, then no words starting with ‘a’ could be formed: ‘a’ is a valid word. Four is generally a good setting. Since this is essentially a vocabulary building game, if the minimum length was three, players could challenge words like ‘arm’ and ‘cat’ which are fairly trivial, so it seemed logical to use set to least four so words like ‘armature’ and ‘catalog’ can be safely made. Please note this does <b>not</b> apply to <b>invalidity</b>. So if a player adds to make the fragment ‘xd’ (haha) then the next player is legally allowed to call out the bluff, irrespective of this fragment being just two letters long.</span>
    <img :src="require('@/assets/settingsScreen.png')"/>
    </th></tr>
    <tr><th>
    <span class="question"> Q. How does challenging ‘valid’ words work in multiplayer?  </span>
    </th></tr>
    <tr><th class="answer">
    <span> It’s a little special - to safeguard a good player’s interests. Imagine you were competing with two computer bots. You started with ’s.’ The game went on: ‘m’ - ‘a’ - ‘r’ - ’t’ [alphabets you would say are underlined]. At this point, the second bot should challenge the first one since the valid word ‘smart’ has been formed, but bots can play non-ideally. So what if the second bot colluded with the first one, and ended up saying ‘l’ (as in London)! So the word fragment becomes ‘smartl’ and now it’s your turn. You shouldn’t have to say ‘y’ and lose. You should be able to point out that ‘smart’ is a valid word. That’s exactly it. So, in multiplayer, you need to ‘depress’ the letter that makes the valid word, and then hit the ‘Valid’ button. So here, you would press ’t’ on the fragment, and then hit the ‘Valid’ button.</span>
    <img :src="require('@/assets/challengingMultiplayer.gif')"/>
    </th></tr>
    <tr><th>
    <span class="question"> Q. What’s ‘Analyze’? </span>
    </th></tr>
    <tr><th class="answer">
    <span> 'Analyze' is an experimental tab that allows you to see how the game could have progressed ideally (no bluffs) after the first three letters of the fragment. Nodes highlighted in green are words you could have made to win, and nodes in red are words which would have resulted in a loss for you. Hovering the mouse over a node reveals the word in the top right corner of the screen, and you can also click on nodes to open their definitions in new tabs, powered by Merriam-Webster’s Dictionary!</span>
    <img :src="require('@/assets/analyzeScreen.gif')"/>
    </th></tr>
    <tr><th class="answer">
    <span class="question"> Q. Sample gameplay GIFs? </span>
    <span> 1v1 BuffBot. Player starts with 'a', loses on aqua.</span>
    <img :src="require('@/assets/gameplay1.gif')"/>
    <br><br>
    <span> Multiplayer vs 2 BabyBots. Player starts with 'i', bots extend to 'irk' (minimum length is 4 so no challenge here), player continues to say 'i' and eventually wins the game after an incorrect bluff challenge by the first BabyBot. </span>
    <img :src="require('@/assets/gameplay2.gif')"/>
    </th></tr>
    <tr><th>
    <h2 class="bottom"> Whatever you do, just make it long. </h2>
    </th></tr>
    </tbody>
    </table>
</template>

<script>
import RaisedIconButton from '@/components/global/raisedIconButton.vue'
export default {
  name: 'RulesScreen',
  components: {
    RaisedIconButton,
  }
}
</script>

<style scoped>
h2 {
    font-weight: 400;
    color: #7a81ff;
    font-size: calc(max(2.5vw, 2.5vh));
}
.bottom {
    margin-bottom: 5%;
}
.subtitle {
    margin-bottom: 0;
}
h1 {
    margin-top: 0;
    color: #011993;
    font-size: calc(max(4vw, 4vh));
}
span {
    font-size: calc(max(1.5vw, 1.5vh));
}
.question {
    font-weight: 600;
}
.answer {
    font-weight: 300;
    padding-left: 7%;
    text-align: justify;
}
.subheading {
    font-weight: 500;
}
table {
    position: fixed;
    left: 5%;
    top: 12%;
    width: 92%;
    height: 95%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0 1em;
}
tbody {
    display: block;
    overflow-y: scroll;
    height: 100%;
    max-height: 100%;
    -ms-overflow-style: none; 
    min-height: 0;
    scrollbar-width: none;
}
tbody::-webkit-scrollbar {
  display: none;
}
img {
    display: block;
    margin-top: 2%;
    margin-bottom: 2%;
    width: 100%;
    height: calc(min(80vw, 80vh));
    margin-left: 0;
}

</style>