<template>
    <button :style="style" @click="navigate()"><span>{{label}}</span> <img :src="require(`@/assets/${icon}.svg`)" alt="img" :style="`filter: brightness(0) saturate(100%) invert(26%) sepia(20%) saturate(1132%) hue-rotate(176deg) brightness(91%) contrast(88%); position: absolute; top: ${this.imgTop}; left: ${this.imgLeft}; width: ${this.imgSide}; height: ${this.imgSide};`"/> </button>
</template>

<script>
export default {
    name: 'RaisedIconButton',
    props: [
        'label',
        'icon',
        'top',
        'left',
        'width',
        'height',
        'imgTop',
        'imgLeft',
        'imgSide',
        'toScreen',
    ],
    methods: {
        navigate() {
            if(this.toScreen === "back") {
                return this.$router.go(-1);
            }
            return this.$router.push({path: this.toScreen});
        }
    },
    computed: {
        style() {
            return `position: fixed; top: ${this.top}; left: ${this.left}; width: ${this.width}; height: ${this.height};`
        },
    }
}
</script>

<style scoped>
button {
    border: none;
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7), 10px 10px 10px rgba(174, 174, 192, 0.5);
    border-radius: 1vw;
    padding: calc(max(2vw, 2vh));
    background-color: #f0f0f3;
}
button:active {
    transform: translateY(1vh);
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7) inset, 10px 10px 10px rgba(174, 174, 192, 0.2) inset;
}
span {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    float: right;
    position: relative;
    right: 40%;
    font-size: calc(max(3vw, 3vh));
    font-weight: 800;
    color: #354a68;
}
</style>