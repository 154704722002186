<template>
<router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in" appear>
            <div :key="Math.random()">
             <component :is="Component" />
            </div>
  </transition>
</router-view>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
body, html {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
  background-color: #f0f0f3;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
