import { defineStore } from "pinia";
import { useSettingsStore } from "@/stores/settingsStore";
export const useSessionWordStore = defineStore(
    {
        id: 'sessionWordStore',
        state: () => ({
            currentPlayers: ['You', 'BabyBot'],
            wordsPlayed: [],
            lastPlayerEndIndex: 0,
        }),
        actions: {
            setPlayers(choice) {
                this.currentPlayers = ['You', 'Babybot'];
                const settingsStore = useSettingsStore();
                if(choice === "BotClub") {
                    var generatedPlayers = ['You'];
                    for(let i = 0; i < settingsStore.numBotsMultiplayer; i++) {
                        generatedPlayers.push(['BabyBot', 'BuffBot', 'BoorBot'][settingsStore.multiplayerMixed ? Math.floor(Math.random() * settingsStore.difficultyMultiplayer) : (settingsStore.difficultyMultiplayer - 1)] + '_' + i.toString())
                    }
                    this.currentPlayers = generatedPlayers;
                } else {
                this.currentPlayers[1] = choice;
                }
            },
        }
    }
)