<template>
    <vue3-snackbar top :duration="8000"></vue3-snackbar>
    <Transition name="fade" mode="out-in" appear>
    <div>
    <RaisedIconButton top="calc(min(5vw, 5vh))" left="calc(min(5vw, 5vh))" width="calc(min(10vw, 10vh))" height="calc(min(10vw, 10vh))" imgTop="calc(min(1.5vw, 1.5vh))" imgLeft="calc(min(1.5vw, 1.5vh))" imgSide="calc(min(7vw, 7vh))" label="" icon="home" toScreen="/"/>
    <div v-if="notTimeEnd">
    <h1> Results </h1>
    <h2 class="subtitle"> {{p1}} called a {{event}} on {{p2}}. </h2>
    <h2 class="subheading"> {{p1}} {{result}}. </h2>
    <h3 v-html="info"></h3>
    </div>
    <div v-else>
        <h2 class="subtitle"> Game Ended on Time </h2>
        <h2 class="subheading"> {{pWord}} </h2>
    </div>
    <button class="replay" @click="replay"> <span>Replay</span> </button>
    <button class="analyze" @click="analyze"> <span>Analyze</span> </button>
    </div>
    </Transition>
</template>

<script>
import {useResultsStore} from '@/stores/resultsStore';
import RaisedIconButton from '@/components/global/raisedIconButton.vue'
import { useSessionWordStore } from '@/stores/sessionWordStore';
import { useGameScreenStore } from '@/stores/gameScreenStore';
import { useSnackbar } from 'vue3-snackbar';
import { useDictionaryStore } from '@/stores/dictionaryStore';
export default {
    name: 'ResultsScreen',
    setup() {
        const resultsStore = useResultsStore();
        const sessionWordStore = useSessionWordStore();
        const gameScreenStore = useGameScreenStore();
        const dictionaryStore = useDictionaryStore();
        const snackbar = useSnackbar();
        const p1 = resultsStore.p1;
        const p2 = resultsStore.p2;
        const event = resultsStore.event;
        const result = resultsStore.result;
        const info = resultsStore.info;
        return {p1, p2, event, result, info, sessionWordStore, gameScreenStore, snackbar, dictionaryStore};
    },
    created() {
        this.notTime();
    },
    methods: {
        notTime() {
            return this.gameScreenStore.timerArray[this.gameScreenStore.currentPlayerIndex] >= 0;
        },
        possibleWord() {
            var words = this.dictionaryStore.trie.search(this.gameScreenStore.currentWordFragment);
            return words.length === 0 ? ('No word possible after ' + this.gameScreenStore.currentWordFragment) : (words[Math.floor(Math.random() * words.length)] + ' was possible from here.');
        },
        replay() {
            // for p1
            if(!this.notTime()) {
                this.$router.push({path: '/game'});
                return;
            }
            if(this.result === 'lost') {
                // p2 wins 
                this.sessionWordStore.lastPlayerEndIndex = this.sessionWordStore.currentPlayers.indexOf(this.p2);
            } else {
                this.sessionWordStore.lastPlayerEndIndex = this.sessionWordStore.currentPlayers.indexOf(this.p1);
            }
            this.$router.push({path: '/game'});
        },
        analyze() {
            console.log(this.sessionWordStore.lastPlayerEndIndex);
            if(this.gameScreenStore.currentWordFragment.length < 3) {
                this.snackbar.clear();
                this.snackbar.add({type: 'warning', title: 'Error', text: 'Fragment is too small to analyze.'});
                return;
            } else {
            this.$router.push({path: '/analyze'});
            }
        }
    },
    computed: {
        notTimeEnd() {
            return this.notTime();
        },
        pWord() {
            return this.possibleWord();
        }
    },
    components: {
        RaisedIconButton,
    }
}
</script>

<style scoped>
div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
h1 {
    margin-top: 2%;
    font-size: calc(max(6vw, 6vh));
    font-weight: 600;
    color: #354a68;   
}
h2 {
    font-size: calc(max(4vw, 4vh));
    font-weight: 550;
    color: #009688; 
    margin: 1%;        
}
.subtitle {
    position: fixed;
    top: 20%;
}
.subheading {
    position: fixed;
    top: 30%;
}
h3 {
    position: fixed;
    bottom: 30%;
    font-size: calc(max(3vw, 3vh));
    font-weight: 450;
    color: #3f51b5;
    margin: 1%;          
}
button {
    background-color: #f0f0f3 !important;
    border: none;
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7), 10px 10px 10px rgba(174, 174, 192, 0.5);
    border-radius: 1vw;
    padding: calc(max(2vw, 2vh));
}
button:active {
    transform: translateY(1vh);
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7) inset, 10px 10px 10px rgba(174, 174, 192, 0.2) inset;
}
.replay {
    position: fixed;
    top: 80vh;
    left: calc(min(10vw, 10vh));
    width: calc(min(35vw, 40vh));
    height: calc(min(15vw, 15vh));
}
.analyze {
    position: fixed;
    top: 80vh;
    right: calc(min(10vw, 10vh));
    width: calc(min(35vw, 40vh));
    height: calc(min(15vw, 15vh));
}
span {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: calc(max(2.5vw, 2.5vh));
    font-weight: 600;
    color: #354a68;
}
</style>