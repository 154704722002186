<template>
    <div>
    <RaisedIconButton top="calc(min(5vw, 5vh))" left="calc(100% - min(15vw, 15vh))" width="calc(min(10vw, 10vh))" height="calc(min(10vw, 10vh))" imgTop="calc(min(1.5vw, 1.5vh))" imgLeft="calc(min(1.5vw, 1.5vh))" imgSide="calc(min(7vw, 7vh))" label="" icon="settings" toScreen="settings"/>
    <RaisedIconButton top="calc(min(5vw, 5vh))" left="calc(min(5vw, 5vh))" width="calc(min(10vw, 10vh))" height="calc(min(10vw, 10vh))" imgTop="calc(min(1.5vw, 1.5vh))" imgLeft="calc(min(1.5vw, 1.5vh))" imgSide="calc(min(7vw, 7vh))" label="" icon="backbutton" toScreen="back"/>
    <div class="upper"><span> 1v1 </span></div>
    <PlayerCard left="calc(50% - min(25vw, 25vh))" top="19vh" avatar="baby" label="BabyBot"/>
    <PlayerCard left="calc(50% - min(25vw, 25vh))" top="34vh" avatar="buff" label="BuffBot"/>
    <PlayerCard left="calc(50% - min(25vw, 25vh))" top="49vh" avatar="boor" label="BoorBot"/>
    <div class="lower"><span> Multiplayer </span></div>
    <PlayerCard left="calc(50% - min(25vw, 25vh))" top="79vh" avatar="multi" label="BotClub"/>
    </div>
</template>

<script>
import RaisedIconButton from '../global/raisedIconButton.vue'
import PlayerCard from '../playerSelection/playerCard.vue'
export default {
    name: 'PlayerSelectionScreen',
    components: {
        RaisedIconButton,
        PlayerCard,
    }
}
</script>

<style scoped>
span {
    font-size: calc(max(3vw, 3vh));
    font-weight: 600;
    color: #354a68; 
}
.upper {
    position: absolute;
    top: 10vh;
    left: 20%;
}
.lower {
    position: absolute;
    top: 70vh;
    left: 20%;
}
</style>