import { defineStore } from "pinia";
import { useSettingsStore } from '@/stores/settingsStore'
import { useSessionWordStore } from "@/stores/sessionWordStore";

export const useGameScreenStore = defineStore(
    {
        id: 'gameScreenStore',
        state: () => ({
            currentWordFragment: '',
            currentPlayerIndex: 0,
            timerArray: [60, 60],
            keypressQueue: [],
            gameActive: false,
            gameEnded: false,
            depressedIndex: -1,
            isUserRespondingToBluff: false,
            frozenBluffFragment: '',
            bluffCompetingPlayer: '',
            }),
        actions: {
            completeGameSetup() {
                const sessionWordStore = useSessionWordStore();
                var currentPlayers = sessionWordStore.currentPlayers;
                this.currentPlayerIndex = sessionWordStore.lastPlayerEndIndex;
                const settingsStore = useSettingsStore();
                this.timerArray = Array(currentPlayers.length).fill(settingsStore.timePerRound);
            }
        }
}
)