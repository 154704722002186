import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHashHistory } from 'vue-router';
import { SnackbarService, Vue3Snackbar } from 'vue3-snackbar';
import "vue3-snackbar/dist/style.css";
import { createPinia } from 'pinia';
import HomeScreen from './components/home/homeScreen'
import PlayerSelectionScreen from './components/playerSelection/playerSelectionScreen'
import SettingsScreen from './components/settings/settingsScreen'
import GameScreen from './components/gameplay/gameScreen'
import ResultsScreen from './components/results/resultsScreen'
import AnalysisScreen from './components/analysis/analysisScreen'
import RulesScreen from './components/rules/rulesScreen'

const routes = [
     { path: '/', component: HomeScreen },
     { path: '/playerSelection', component: PlayerSelectionScreen },
     { path: '/settings', component: SettingsScreen },
     { path: '/game', component: GameScreen },
     {path: '/results', component: ResultsScreen},
     {path: '/analyze', component: AnalysisScreen},
     {path: '/rules', component: RulesScreen}
   ];
  
const router = createRouter({
   history: createWebHashHistory(),
   routes,
 })
  
  const app = createApp(App)
  const pinia = createPinia()

  app.use(SnackbarService);
  app.component("vue3-snackbar", Vue3Snackbar);
  app.use(router)
  app.use(pinia)
  
  app.mount('#app')