import { defineStore } from "pinia";
import TrieSearch from 'trie-search';
import { useSettingsStore } from "@/stores/settingsStore";
export const useDictionaryStore = defineStore(
    {
        id: 'dictionaryStore',
        state: () => ({
            trie: null,
            wordTable: null,
            }),
        actions: {
            // checkStatus() {
            //     var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
            //     const request2 = indexedDB.open('ProLongGameStorage');
            //     request2.onupgradeneeded = (event) => {
            //         console.log("handling upgrade needed.");
            //         var db = event.target.result;
            //         if(!db.objectStoreNames.contains('wordTable')) {
            //             db.createObjectStore('wordTable', {keyPath: 'tableName'});
            //         }
            //         var txn2 = event.target.transaction;
            //         txn2.oncomplete = () => { console.log('created store. writing to storage.'); this.generationRoutine(); this.writeToStorage(db); }
            //     }
            //     request2.onsuccess = (event) => {
            //         console.log("opened.")
            //         this.loadFromStorage(event.target.result);
            //     }
            //     request2.onerror = () => {
            //         console.log('failed to interact with database.');
            //         this.generationRoutine();
            //     }     
            // },
            generationRoutine() {
                const settingsStore = useSettingsStore();
                const dictionary = require('../assets/ProLongWordDatabaseClean.json');
                let dictionaryDict = dictionary['dictionary'];
                this.generateTrie(dictionaryDict);
                this.generateWordTable();
                settingsStore.trieLoaded = true;
            },
            generateTrie(dictionaryDict) {
                    var t = new TrieSearch();
                    console.log("compiling trie")
                    for(let v in dictionaryDict) {
                        t.map(v, v);
                    }
                    this.trie = t;
            },
            generateWordTable() {
                const wt = require('../assets/ProLongWordTableClean.json');
                let wordTable = wt['wordTable'];
                this.wordTable = wordTable;
                for(let u in this.wordTable) {
                    wordTable[u][2] = wordTable[u][1] === 'U' ? (60 + Math.random() * 40) : (Math.random() * 60)
                }
            },
            // writeToStorage(db) {
            //     let wtTransaction = db.transaction('wordTable', 'readwrite');
            //     let r2 = wtTransaction.objectStore('wordTable').add({'tableName': 'wordTable', 'table': JSON.stringify(this.wordTable)});
            //     r2.onsuccess = () => {console.log("done saving word table."); };
            //     r2.onerror = () => {console.log('failed to save word table.'); indexedDB.deleteDatabase('ProLongGameStorage'); };
            // },
            // loadFromStorage(db) {
            //     const settingsStore = useSettingsStore();
            //     this.trie = new TrieSearch();
            //     var wordTableJSON = db.transaction('wordTable', 'readonly').objectStore('wordTable').get('wordTable');
            //     wordTableJSON.onsuccess = (event) => {
            //         console.log("received");
            //         this.wordTable = eval('(' + event.target.result['table'] + ')');
            //         console.log("evaled.");
            //         for(let v in this.wordTable) {
            //         this.trie.map(v, v);
            //         }
            //         console.log('trie generated');
            //         console.log(this.trie.search('aba'));
            //         settingsStore.trieLoaded = true;
            //     }
            // },
        }
}
)