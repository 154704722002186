import { defineStore } from "pinia";

export const useSettingsStore = defineStore(
    {
        id: 'settingsStore',
        state: () => ({
            minLengthOfValidWord: 4,
            numBotsMultiplayer: 2,
            multiplayerMixed: false,
            difficultyMultiplayer: 2, // 1 indexed
            timePerRound: 60,
            trieLoaded: false,
    }),
}
)