<template>
    <button @click="setAndNavigate()" :style="`position: absolute; top: ${top}; left: ${left};`">
        <span class="heading">{{label}}</span>
        <img :src="require(`@/assets/${avatar}.svg`)" alt="img" :style="`filter: brightness(0) saturate(100%) invert(26%) sepia(20%) saturate(1132%) hue-rotate(176deg) brightness(91%) contrast(88%); position: absolute; top: ${spacing}; left: ${spacing}; width: calc(min(8.75vw, 8.75vh)); height: calc(min(8.75vw, 8.75vh));`"/>
        <img :src="require(`@/assets/forwardbutton.svg`)" alt="img" :style="`filter: brightness(0) saturate(100%) invert(26%) sepia(20%) saturate(1132%) hue-rotate(176deg) brightness(91%) contrast(88%); position: absolute; top: ${spacing}; left: calc(95% - ${spacing} - min(8.75vw, 8.75vh)); width: calc(min(8.75vw, 8.75vh)); height: calc(min(8.75vw, 8.75vh));`"/>
    </button>
</template>

<script>
import { useGameScreenStore } from '@/stores/gameScreenStore'
import {useSessionWordStore} from '@/stores/sessionWordStore'
export default {
    name: 'PlayerCard',
    props: [
        'top',
        'left',
        'label',
        'avatar',
    ],
    setup() {
        const gameScreenStore = useGameScreenStore();
        const sessionWordStore = useSessionWordStore();
        sessionWordStore.lastPlayerEndIndex = 0;
        gameScreenStore.$reset();
        return {gameScreenStore, sessionWordStore};
    },
    methods: {
        setAndNavigate() {
            this.sessionWordStore.setPlayers(this.label);
            return this.$router.push({path: 'game'});
        }
    },
    computed: {
        spacing() {
            return `calc(min(1.875vw, 1.875vh))`;
        },
    }
}
</script>

<style scoped>
button {
    position: absolute;
    background-color: #f0f0f3;
    border: none;
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7), 10px 10px 10px rgba(174, 174, 192, 0.5);
    border-radius: 1vw;
    padding: calc(max(2vw, 2vh));
    width: calc(min(50vw, 60vh));
    height: calc(min(12.5vw, 12.5vh));
}
button:active {
    transform: translateY(1vh);
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7) inset, 10px 10px 10px rgba(174, 174, 192, 0.2) inset;
}
.heading {
    font-family: Avenir, Arial, Helvetica, sans-serif;
    font-size: calc(max(2vw, 2vh));
    font-weight: 600;
    color: #354a68; 
}
</style>