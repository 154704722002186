<template>
    <Transition name="fade" mode="out-in" appear>
    <div id="visualization">
    <button @click="fragLen--; if(verifyFragLen()) hierarchy();" class="button1"><span>-</span></button>
    <button @click="fragLen++; if(verifyFragLen()) hierarchy();" class="button2"><span>+</span></button>
    <RaisedIconButton top="calc(min(5vw, 5vh))" left="calc(min(5vw, 5vh))" width="calc(min(10vw, 10vh))" height="calc(min(10vw, 10vh))" imgTop="calc(min(1.5vw, 1.5vh))" imgLeft="calc(min(1.5vw, 1.5vh))" imgSide="calc(min(7vw, 7vh))" label="" icon="backbutton" toScreen="back"/>
    <h1> Analysis (for upto 100 words) for fragment: {{fragment}} </h1>
    <h2> {{w}} </h2>
    </div>
    </Transition>
</template>

<script>
import {useDictionaryStore} from '@/stores/dictionaryStore';
import RaisedIconButton from '@/components/global/raisedIconButton.vue'
import { useGameScreenStore } from '@/stores/gameScreenStore';
import { useSettingsStore } from '@/stores/settingsStore';
import * as d3 from 'd3';
import { useSessionWordStore } from '@/stores/sessionWordStore';
export default {
    name: 'AnalysisScreen',
    setup() {
        const dictionaryStore = useDictionaryStore();
        const gameScreenStore = useGameScreenStore();
        const settingsStore = useSettingsStore();
        const sessionWordStore = useSessionWordStore();
        return {dictionaryStore, gameScreenStore, settingsStore, sessionWordStore}
    },
    data() {
        return {
            w: '',
            fragLen: 0,
            words: [],
            fragment: '',
        }
    },
    methods: {
        getWords() {
            console.log("GetWords FragLen:", this.fragLen);
            var results = this.dictionaryStore.trie.search(this.fragment);
            results = results.map(a => [a,Math.random()]).sort((a,b) => {return a[1] < b[1] ? -1 : 1;}).slice(0,100).map(a => a[0]);
            return results.length === 0 ? false : results;
        },
        generateHierarchyForD3(words) {
            var root = words[0].slice(0, this.settingsStore.minLengthOfValidWord - 1);
            var rootLine = root + ',';
            var lines = [rootLine];
            var text = 'name,parent\n';
            text += rootLine + '\n';
            for(let word of words.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)) {
                // aas,
                // v_aas, aas
                // o_aasv, v_aas
                // e_aasvo, o_aasv
                // l_aasvoe, e_aasvo
                // v_aas, aas
                // o_aasv, v_aas
                // g_aasvo, o_aasv
                // y_abut
                var child = '';
                var parent = '';
                var f = '';
                for(let i = this.settingsStore.minLengthOfValidWord - 1; i < word.length; i++) {
                    parent = child === '' ? word.slice(0, i) : child;
                    child = word[i] + '_' + word.slice(0, i);
                    f = child + ',' + parent;
                    if(!lines.includes(f)) {
                        lines.push(f);
                        text += f + '\n';
                    }
                }
            }
            return text;
        },
        hierarchy() {
            console.log("Hierarchy FragLen:", this.fragLen);
            this.words = this.getWords();
            if(this.words) {
                this.w = '';
                var root = d3.csvParse(this.generateHierarchyForD3(this.words));
                root = d3.stratify().id(d => d['name']).parentId(d => d['parent'])(root);
                const width = window.innerWidth * 0.8;
                const height = window.innerHeight * 0.8;
                const radius = Math.min(width * 0.5, height * 0.5);
                const svg = d3.select("#visualization").append("svg").attr("width", width).attr("height", height).append("g").attr("transform", `translate(${width / 2},${height / 2})`).attr("font-size", 10);
                const cluster = d3.cluster().size([360, radius * 0.9]);
                cluster(root);
                const linksGenerator = d3.linkRadial().angle(function(d) { return d.x / 180 * Math.PI; }).radius(function(d) { return d.y; });
                svg.selectAll('path').data(root.links()).join('path').attr("d", linksGenerator).style("fill", 'none').attr("stroke", '#354a68').style("stroke-width", 3);
                var nodes = svg.selectAll("g").data(root.descendants()).join("g").attr("transform", function(d) { return `rotate(${d.x - 90}) translate(${d.y})`;});
                var self = this;
                console.log(self.sessionWordStore.lastPlayerEndIndex);
                nodes.append("circle").attr("r", 8).style("fill", d => (d.data.name.includes('_') ? (self.words.includes(d.data.name.slice(2) + d.data.name[0])) : false) ? ((self.sessionWordStore.currentPlayers[(d.data.name.length - 2 + self.sessionWordStore.lastPlayerEndIndex) % self.sessionWordStore.currentPlayers.length] === 'You') ? 'red' : 'green') : 'white').attr("stroke", "#354a68").style("stroke-width", 3).append('text').text((d) => d.data.name);
                nodes.on("click", (d) => {var c = d['target'] ?? d['toElement']; ['green', 'red'].includes(c['style']['fill']) ? window.open('https://www.merriam-webster.com/dictionary/' + c['textContent'].slice(2) + c['textContent'][0], '_blank') : ''}).on("mouseover", (d) => {var c = d['target'] ?? d['toElement']; self.w = (c['textContent'].includes('_') ? (c['textContent'].slice(2) + c['textContent'][0]) : '')}).on("mouseleave", () => (self.w = ''));
                return '';
             } else {
                this.w = ('No words possible after fragment: ' + this.fragment + '.');
                d3.select("#visualization").selectAll("svg").remove();
             } 
        },
        verifyFragLen() {
            var t = false;
            if(this.fragLen > (this.gameScreenStore.currentWordFragment.length - 1)) {
                this.fragLen = (this.gameScreenStore.currentWordFragment.length) - 1;
                t = true;
            }
            if(this.fragLen < (this.settingsStore.minLengthOfValidWord - 1)) {
                this.fragLen = this.settingsStore.minLengthOfValidWord - 1;
                t = true;
            }
            if(!t) {
            this.fragment = this.gameScreenStore.currentWordFragment.slice(0, this.fragLen);
            d3.select("#visualization").selectAll("svg").remove();
            }
            return !t;
        },
    },
    mounted() {
        this.w = '';
        this.fragLen = this.settingsStore.minLengthOfValidWord - 1;
        this.fragment =  this.gameScreenStore.currentWordFragment.slice(0, this.fragLen);
        this.hierarchy();
    },
    components: {
        RaisedIconButton,
    }
}
</script>

<style scoped>
h1 {
    margin-top: 5%;
    font-size: calc(max(3vw, 3vh));
    font-weight: 600;
    color: #354a68;   
}
h2 {
    font-size: calc(max(2vw, 2vh));
    font-weight: 600;
    color: #000;  
    position: fixed;
    right: 10%;
    top: 20%;
}

button {
    border: none;
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7), 10px 10px 10px rgba(174, 174, 192, 0.5);
    border-radius: 0.5vw;
    padding: calc(max(1.5vw, 1.5vh));
    background-color: #f0f0f3;
    font-size: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button1 {
    position: fixed;
    left: 48%;
    top: 1.5%;
    width: calc(max(2vw, 2vh));
    height: calc(max(2vw, 2vh));
}

.button2 {
    position: fixed;
    left: 52%;
    top: 1.5%;
    width: calc(max(2vw, 2vh));
    height: calc(max(2vw, 2vh));
}

button:active {
    transform: translateY(1vh);
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7) inset, 10px 10px 10px rgba(174, 174, 192, 0.2) inset;
}

span {
    color: #354a68;
}

</style>