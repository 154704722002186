<template>
    <vue3-snackbar top :duration="8000"></vue3-snackbar>
    <Transition mode="out-in" name="fade">
    <div v-if="isUserManagingBluff">
            <h1>Well, somebody thinks <span :style="`color: ${getHumanColor}`">You</span> are bluffing. Type to complete your word and hit Enter. </h1>
            <div class="wordFrame">
                <TransitionGroup name="word">
                        <button v-for="(l, index) in currentFragment" class="letterButton" :key="l + index.toString()" :id="l + index.toString()">
                            <span :style="`color: ${getHumanColor}`">{{l}}</span>
                        </button>
                </TransitionGroup>
            </div>
    </div>
    <div v-else>
    <div class="timer" :style="`border: ${getHumanColor} solid 7.5px;`"><span :style="`color: ${getHumanColor}`">{{active ? humanTimeLeft : ended ? '◼' : '| |'}}</span></div>
    <div class="wordFrame">
        <div v-if="active && currentFragment.length > 0">
        <TransitionGroup name="word">
                <button v-for="(l, index) in currentFragment" :class="index <= depressed ? 'depressedButton' : 'letterButton'" :key="l + index.toString()" @click="depressButtons(index)" :id="l + index.toString()">
                    <span :style="`color: ${playerColors[(index + startOffsetIndex) % totalPlayers]}`">{{l}}</span>
                </button>
        </TransitionGroup>
        </div>
        <div v-else-if="!active && ((latestValidKeypress ?? true) === true)">
            <span :style="`color: ${getHumanColor}; opacity: 0.5`"> Type a letter and hit Enter to start </span> 
        </div>
        <div :v-if="currentPlayer === 'You'" class="letterButton"> <span :style="`color: ${currentColor}; opacity: 0.5;`">{{latestValidKeypress}}</span> </div> 
    </div>  
    <Transition name="slide-up" mode="out-in"><div class="currentPlayerTime indentLeft" :style="`color: ${currentColor}`" v-if="true" :key="currentPlayer"> {{currentPlayer}} </div></Transition>
    <Transition name="slide-up" mode="out-in"><div class="currentPlayerTime" :style="`color: ${currentColor}`" v-if="currentPlayer !== 'You'" :key="currentPlayer"> {{getCurrentPlayerTime()}} </div></Transition>
    <button class="challenge" @click="challenge"> <span>Valid</span> </button>
    <button class="bluff" @click="bluff"> <span>Invalid</span> </button>
    </div>
    </Transition>
</template>

<script>
import { useGameScreenStore } from '@/stores/gameScreenStore'
import { useDictionaryStore } from '@/stores/dictionaryStore'
import { useSettingsStore } from '@/stores/settingsStore'
import { useSessionWordStore } from '@/stores/sessionWordStore';
import { useResultsStore } from '@/stores/resultsStore';
import { useSnackbar } from "vue3-snackbar";
async function getLetterAsync(object) {
    var self = object;
    return new Promise(resolve => setTimeout(() => {
        var result = self.getBotMove(self.currentFragment, self.currentPlayerIndex);
        resolve(result);
    }, Math.max(6 - self.currentFragment.length, 3) * 1000));
}
export default {
    name: 'GameScreen',
    setup() {
        const gameScreenStore = useGameScreenStore();
        const dictionaryStore = useDictionaryStore();
        const settingsStore = useSettingsStore();
        const sessionWordStore = useSessionWordStore();
        const resultsStore = useResultsStore();
        const snackbar = useSnackbar();
        resultsStore.$reset();
        gameScreenStore.isUserRespondingToBluff = false;
        gameScreenStore.frozenBluffFragment = '';
        gameScreenStore.bluffCompetingPlayer = '';
        var playerColors = [
            '#3f51b5',
            '#f44336',
            '#009688',
            '#795548',
            '#e81e63',
            '#2196f3',
            '#4caf50',
            '#ffc107',
            '#9e9e9e',
            '#9c27b0',
            '#673ab7',
            '#03a9f4',
            '#ff9800',
            '#607d8b',
            '#00bcd4',
            '#ff5722', 
            '#000000',
        ];
        var rotateRightColorIndex = Math.floor(Math.random() * playerColors.length);
        playerColors = playerColors.slice(rotateRightColorIndex).concat(playerColors.slice(0, rotateRightColorIndex));
        dictionaryStore.generationRoutine();
        let nonIdealMoveProbability = {'BabyBot': 0.8, 'BuffBot': 0.3, 'BoorBot': 0.05};
        var groupBy = function(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        };
        return {gameScreenStore, dictionaryStore, settingsStore, groupBy, nonIdealMoveProbability, sessionWordStore, playerColors, resultsStore, snackbar};
    },
    mounted() {
        this.snackbar.clear();
        if(this.sessionWordStore.currentPlayers[this.gameScreenStore.currentPlayerIndex] !== 'You') {
            this.gameActive = true;
            var result = this.getBotMove('', this.currentPlayerIndex);
            this.gameScreenStore.keypressQueue.push(result);
            this.gameScreenStore.keypressQueue.push('Enter');
            this.updateFragment();
        }
    },
    methods: {
        updateTimer() {
            if(this.gameScreenStore.gameActive && !this.gameScreenStore.gameEnded) {
                if(parseInt(this.currentPlayerTime) <= 0) {
                    
                    this.gameScreenStore.gameActive = false;
                    this.gameScreenStore.gameEnded = true;
                    this.$router.push('/results');
                }
                setTimeout(() => {                    
                this.timers[this.gameScreenStore.currentPlayerIndex]--;
                this.updateTimer();
                }, 1000);
            }
        },
        depressButtons(index) {
            this.gameScreenStore.keypressQueue.push('Enter');
            if(this.gameScreenStore.depressedIndex != (-1)) {
                this.gameScreenStore.depressedIndex = -1;
                return; 
            }
            if((index + 1) < this.settingsStore.minLengthOfValidWord) {
                this.snackbar.clear();
                this.snackbar.add({type: 'warning', title: 'Error', text: 'Your minimum word length is set to ' + this.settingsStore.minLengthOfValidWord.toString() + '.'});
                return;
            } else if(this.currentPlayer !== 'You'){
                   this.snackbar.clear();
                   this.snackbar.add({type: 'warning', title: 'Error', text: 'Please wait for your turn to challenge other players.'});
                return;
            }
            else {
                this.gameScreenStore.depressedIndex = index;
            }
        },
        willIdealMove(playerIndex) {
            for(let i in this.nonIdealMoveProbability) {
                if(this.sessionWordStore.currentPlayers[playerIndex].includes(i)) {
                    return (Math.random() > this.nonIdealMoveProbability[i]);
                }
            }
        },
        gameEnd(event) {
            this.gameScreenStore.gameActive = false;
            this.gameScreenStore.gameEnded = true;
            this.sessionWordStore.wordsPlayed.push(this.currentFragment);
            var result = '';
            var p1 = this.currentPlayer;
            var p2 = '';
            var p2Index = 0;
            var resultPossibilities = ['lost', 'won'];
            var endWords = ['correctly', 'incorrectly'];
            var infoPossibilities = ['invalid', 'valid'];
            var wordToAnalyze = (this.depressed !== -1 ? this.currentFragment.slice(0, this.depressed + 1) : this.currentFragment);
            var truth = (wordToAnalyze in this.dictionaryStore.wordTable);
            var info = (truth ? (`'<a href="https://www.merriam-webster.com/dictionary/${wordToAnalyze}" target="_blank" rel="noreferrer noopener">` + wordToAnalyze + '</a>\'') : ("'" + wordToAnalyze + "'")) + ' is ' + infoPossibilities[+ truth] + '. The ' + event + ' was called ';
            if(event === 'bluff') {
                p2Index = (this.currentPlayerIndex - 1) < 0 ? (this.totalPlayers - 1) : (this.currentPlayerIndex - 1);
                p2 = this.sessionWordStore.currentPlayers[p2Index]; // the player who supposedly bluffed
                if(p2 === 'You') {
                    // user has been asked to complete the bluff
                    this.gameScreenStore.isUserRespondingToBluff = true;
                    this.gameScreenStore.frozenBluffFragment = this.currentFragment;
                    this.gameScreenStore.bluffCompetingPlayer = p1;
                    this.currentPlayer = 'You';
                    return;
                } else {
                    // either user bluffed bot or bot bluffed bot
                    var foundWord = false;
                    var p2Type = p2.replace(/[^A-Za-z]/g, '');
                    console.log(p2Type, this.nonIdealMoveProbability);
                    for(let j in this.dictionaryStore.wordTable) {
                        if(j.startsWith(this.currentFragment)) {
                            if(this.dictionaryStore.wordTable[j][2] <= (1.2 - this.nonIdealMoveProbability[p2Type]) * 100) {
                            result = 'lost';
                            info = p2 + ` was successfully able to quote '<a href="https://www.merriam-webster.com/dictionary/${j}" target="_blank" rel="noreferrer noopener">` + j + '</a>\' as a valid word possible from here.';
                            foundWord = true;
                            }
                        }
                    }
                    if(!foundWord) {
                        result = 'won';
                        info = p2 + ' wasn\'t able to come up with a successful word after \'' + this.currentFragment + '.\''; 
                    }
                }
            } else {
                p2Index = (wordToAnalyze.length - 1 + this.startOffsetIndex) % this.totalPlayers;
                p2 = this.sessionWordStore.currentPlayers[p2Index];
                result = resultPossibilities[+ truth];
                info += endWords[+ !truth];
                info += '.';
            }
            console.log(event, p1, p2, result, info, truth);
            // if(result === 'won') {
            //     this.sessionWordStore.lastPlayerEndIndex = this.currentPlayerIndex;
            // } else {
            //     this.sessionWordStore.lastPlayerEndIndex = p2Index;
            // }
            this.resultsStore.p1 = p1;
            this.resultsStore.p2 = p2;
            this.resultsStore.result = result;
            this.resultsStore.info = info;
            this.resultsStore.event = event;
            this.$router.push({path: '/results'});
        },
        bluff() {
                this.gameEnd('bluff');
        },
        challenge() {
            if(this.currentFragment.length >= this.settingsStore.minLengthOfValidWord) {
                if(this.depressed === -1 && this.currentPlayer === 'You') {
                    if(this.totalPlayers === 2) {
                        this.gameEnd('challenge');
                    } else {
                        // error: no fragment depressed.
                        this.snackbar.clear();
                        this.snackbar.add({type: 'warning', title: 'Error', text: 'First depress the word fragment button on which you think the word ends, then press \'Challenge\''});
                    }
                } else {
                this.gameEnd('challenge');
                }
            } else {
                this.snackbar.clear();
                this.snackbar.add({type: 'warning', title: 'Error', text: 'Your minimum word length is set to ' + this.settingsStore.minLengthOfValidWord.toString() + '.'});                    
            }
        },
        async updateFragment() {
            if(!this.gameScreenStore.gameEnded) {
                var targetIndex = this.gameScreenStore.keypressQueue.length - 2;
                var inputtedLetter = this.gameScreenStore.keypressQueue.slice(targetIndex, targetIndex + 1)[0];
                if(inputtedLetter[0] === '!') {
                    this.gameScreenStore.gameEnded = true;
                    this.gameEnd('bluff');
                } else if(inputtedLetter[0] === '$') {
                    this.gameScreenStore.gameEnded = true;
                    this.gameEnd('challenge');
                }
                if(inputtedLetter.match(/[a-z]/i) && inputtedLetter.length === 1) {
                    this.gameScreenStore.currentWordFragment += inputtedLetter;
                    this.gameScreenStore.currentPlayerIndex += 1;
                    this.gameScreenStore.currentPlayerIndex %= this.gameScreenStore.timerArray.length;
                    if(this.currentFragment.length === 1) {
                        this.gameScreenStore.gameActive = true;
                        this.updateTimer();
                }
                }
                if(this.currentPlayer != "You" && !this.gameScreenStore.gameEnded) {
                    await getLetterAsync(this).then((result) => {
                        this.gameScreenStore.keypressQueue.push(result);
                        this.gameScreenStore.keypressQueue.push('Enter');
                        this.updateFragment();
                    });
                }
            }
        },
        getBotMove(wordFragment, index) {
            // $ -> call challenge, ! -> call bluff
            var playerType = this.currentPlayer;
            playerType = playerType.replace(/[^A-Za-z]/g, '');
            console.log(this.currentPlayer);
            var roundErrorTerm = (this.willIdealMove(index) ? 0 : 1);
            var letters = "abcdefghijklmnopqrstuvwxyz";
            var vocabularyStrengthFactor = 1 + Math.random() * 0.3;
            if(wordFragment.length >= this.settingsStore.minLengthOfValidWord) {
                // check for challenge
            for(let l = (Math.max(wordFragment.length - this.totalPlayers + 1, this.settingsStore.minLengthOfValidWord)); l <= wordFragment.length; l++) {
                if(wordFragment.slice(0, l + 1) in this.dictionaryStore.wordTable) {
                    console.log("this is a valid word.")
                    if(this.willIdealMove(index)) {
                        return '$' + l.toString();
                    }
                }
            }
        }
        else if(wordFragment.length === 0) {
            return letters[Math.floor(Math.random() * letters.length)];
        }
            var results = [];
            for(var l of letters) {
                var possibleWordsUponAddingL = this.dictionaryStore.trie.search(wordFragment + l);
                var groupedByLengthWords = this.groupBy(possibleWordsUponAddingL, 'length');
                var wins = 0;
                var losses = 0;
                var unabridged = 0;
                var abridged = 0;
                for(var length in groupedByLengthWords) {
                    var possibleWordsUponAddingLOfGivenLength = groupedByLengthWords[length];
                    for(var w of possibleWordsUponAddingLOfGivenLength) {
                        if(w.length < this.settingsStore.minLengthOfValidWord) {
                            continue;
                        }
                        // unabridged: 60 - 100
                        if(this.dictionaryStore.wordTable[w][2] > (vocabularyStrengthFactor - this.nonIdealMoveProbability[playerType]) * 100) {
                            continue;
                        }    
                        if(this.dictionaryStore.wordTable[w][1] === 'U') {
                            unabridged += 1;
                        } else {
                            abridged += 1;
                        }
                        var filteredContainingLengths = this.dictionaryStore.wordTable[w][0].filter((x) => x > Math.max(wordFragment.length, this.settingsStore.minLengthOfValidWord));
                        if(filteredContainingLengths.length === 0) {
                            // ideal word
                            if((length - index - 1) % this.totalPlayers === 0) {
                                // loss
                                losses += 1;
                            } else {
                                wins += 1;
                            }
                        } // non ideal word
                        else {
                            var potentialResults = [];
                            var loss = false;
                            for(var cl of filteredContainingLengths) {
                                if((cl - index - 1) % this.totalPlayers === 0) {
                                    if(this.willIdealMove(index)) {
                                    potentialResults.push('L');
                                    loss = true;
                                    } else {
                                        potentialResults.push(['L', 'W'][((length - index - 1) % this.totalPlayers) > 0]);
                                    }
                                } else {
                                    if(loss && this.willIdealMove(index)) {
                                        potentialResults.push('L');
                                    } else {
                                    potentialResults.push('W');
                                    }
                                }
                            }
                            var ls = potentialResults.filter((x) => x === 'L').length;
                            losses += ls;
                            wins += potentialResults.length - ls;
                        }   
                    }
                }
                //console.log('for fragment:', wordFragment, 'if I say', l, 'I think my results are - L:', losses, 'W: ', wins, 'U:', unabridged, 'A:', abridged);
                var wlPercentage = wins/(wins + losses);
                //console.log(roundErrorTerm);
                results.push([l, losses + wins === 0 ? 0 : (roundErrorTerm ? wins * wlPercentage : wlPercentage), unabridged === 0 ? abridged : abridged / unabridged, wins === 0, losses === 0]);
            }
            // if baby: if round error: also filter on letters where losses can happen
            var finalChoices = results.filter((x) => !(x[3] === true && x[4] === true) && (roundErrorTerm ? (x[4] === false) : true)).sort((a, b) => a[1] > b[1]);
            //console.log(finalChoices);
            if(finalChoices.length === 0) {
                // choose to bluff
                if(!this.willIdealMove(index) && !this.willIdealMove(index) && !this.willIdealMove(index)) {
                    return letters[Math.floor(Math.random() * 26)];
                } else {
                    return '!';
                }
            } else {
                // baby will choose any of its top 20
                // buff will choose any of its top 8
                // boor will choose any of its top 5
                var numTries = 5;
                var bounds = Math.min(Math.ceil(5 / (1 - this.nonIdealMoveProbability[playerType])), finalChoices.length);
                var finalSubspace = finalChoices.slice(Math.max(finalChoices.length - bounds, 0));
                console.log(finalSubspace, bounds, this.sessionWordStore.wordsPlayed);
                for(let t = 0; t < numTries; t++) {
                var finalOutput = '';
                if(wordFragment.length < this.settingsStore.minLengthOfValidWord && Math.random() < 0.7 && this.willIdealMove(index)) {
                        finalOutput = finalChoices[Math.floor(Math.random() * finalChoices.length)][0];
                } else {
                var randomIndex = Math.random() * finalSubspace.length;
                finalOutput = finalSubspace[Math.floor(randomIndex)][0];
                }
                var isInPlayed = this.sessionWordStore.wordsPlayed.map((x) => x.startsWith(wordFragment + finalOutput) ? 1 : 0).reduce((partialSum, a) => partialSum + a, 0);
                console.log(finalOutput, isInPlayed)
                if(isInPlayed) {
                    // it's been played before in this session!
                    continue;
                } else {
                    return finalOutput;
                }
              }
            }
            return finalChoices[Math.floor(Math.random() * finalChoices.length)][0];
        },
        getCurrentPlayerTime() {
            console.log(this.gameScreenStore);
            return this.gameScreenStore.timerArray[this.gameScreenStore.currentPlayerIndex].toString();
        },
        handleKeydown(e) {
            if(this.currentPlayer !== "You") {
                return;
            }
            this.gameScreenStore.depressedIndex = -1;
            if(e.key == "." && this.currentFragment.length > 0) {
                this.gameScreenStore.gameActive = !this.gameScreenStore.gameActive;
                if(this.active) {
                this.updateTimer();
                }
            } else if(this.active || this.currentFragment.length === 0) {
            this.gameScreenStore.keypressQueue.push(e.key);
            if(e.key == "Enter") {
                this.updateFragment();
            } else if(!(e.key.length === 1 && e.key.match(/[a-z]/i))) {
                console.log("invalid input!");
            }
            } else if(this.ended) {
                if('abcdefghijklmnopqrstuvwxyz'.includes(e.key)) {
                    this.gameScreenStore.currentWordFragment = this.currentFragment + (e.key);
                } else if(e.key === 'Backspace' && this.gameScreenStore.currentWordFragment.length > this.gameScreenStore.frozenBluffFragment.length) {
                    this.gameScreenStore.currentWordFragment = this.currentFragment.slice(0, this.currentFragment.length - 1);
                } else if(e.key === 'Enter') {
                    var result = '';
                    var info = '';
                    if(this.currentFragment in this.dictionaryStore.wordTable) {
                        result = 'lost';
                        info = 'You correctly said \'' + this.currentFragment + '\' is a valid word.'
                    } else {
                        result = 'won';
                        info = 'You incorrectly thought \'' + this.currentFragment + '\' is a valid word.'
                    }
                    /*
                    // if(result === 'won') {
                    //     this.sessionWordStore.lastPlayerEndIndex = this.sessionWordStore.currentPlayers.indexOf(this.gameScreenStore.bluffCompetingPlayer);
                    // } else {
                    //     this.sessionWordStore.lastPlayerEndIndex = this.humanIndex;
                    // }
                    */
                    this.resultsStore.p1 = this.gameScreenStore.bluffCompetingPlayer;
                    this.resultsStore.p2 = 'You';
                    this.resultsStore.result = result;
                    this.resultsStore.info = info;
                    this.resultsStore.event = 'bluff';
                    this.$router.push({path: '/results'});
                }
            }
        }
    },
    created() {
        window.addEventListener('keydown', this.handleKeydown);
        this.gameScreenStore.$reset();
        this.gameScreenStore.completeGameSetup();
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
    },
    computed: {
        startOffsetIndex() {
            return this.sessionWordStore.lastPlayerEndIndex;
        },
        getHumanColor() {
            return this.playerColors[this.humanIndex];
        },
        humanIndex() {
            return this.sessionWordStore.currentPlayers.indexOf('You');
        },
        humanTimeLeft() {
            return this.timers[this.humanIndex];
        },
        totalPlayers() {
            return this.sessionWordStore.currentPlayers.length;
        },
        currentPlayer: {
            get() {
                return this.sessionWordStore.currentPlayers[this.currentPlayerIndex];
            },
            set() {
                this.gameScreenStore.currentPlayerIndex = this.humanIndex;
            }
        },
        currentPlayerIndex() {
            return this.gameScreenStore.currentPlayerIndex;
        },
        currentPlayerTime() {
            return this.getCurrentPlayerTime();
        },
        keypresses() {
            return this.gameScreenStore.keypressQueue;
        },
        timers() {
            return this.gameScreenStore.timerArray;
        },
        active() {
            return this.gameScreenStore.gameActive;
        },
        currentFragment() {
            return this.gameScreenStore.currentWordFragment;
        },
        currentColor() {
            return this.playerColors[this.currentPlayerIndex];
        },
        latestValidKeypress() {
            var validKeypresses = this.keypresses.filter((x) => x === 'Enter' || 'abcdefghijlkmnopqrstuvwxyz'.includes(x));
            var lastEvent = validKeypresses[validKeypresses.length - 1];
            return lastEvent === 'Enter' ? '' : lastEvent;
        },
        depressed() {
            return this.gameScreenStore.depressedIndex;
        },
        ended() {
            return this.gameScreenStore.gameEnded;
        },
        isUserManagingBluff() {
            return this.gameScreenStore.isUserRespondingToBluff;
        }
    },
watch: {
    currentFragment: {
        handler(value) {
            if(value.length === 1) {
                this.gameScreenStore.gameActive = true;
            }
        }
    },
    depressed: {
        handler() {console.log('depressed change detected');}
    },
    isUserManagingBluff: {
        handler() {console.log('user has been told to respond to a bluff!')}
    }
}
}
</script>

<style scoped>
.timer {
    position: fixed;
    left: calc(50% - min(7.5vw, 7.5vh));
    top: 0;
    width: calc(min(10vw, 10vh));
    height: calc(min(10vw, 10vh));
    border-radius: 50%;
    margin-top: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
}
button {
    background-color: #f0f0f3 !important;
    border: none;
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7), 10px 10px 10px rgba(174, 174, 192, 0.5);
    border-radius: 1vw;
    padding: calc(max(2vw, 2vh));
}
.depressedButton {
    box-shadow: none;
}
.letterButton {
    display: inline-flex;
    margin: calc(min(1vw, 1vh));
    width: calc(min(7vw, 7vh));
    height: calc(min(7vw, 7vh));
    padding: 0;
    align-items: center;
    justify-content: center;
}
button:active {
    transform: translateY(1vh);
    box-shadow: -10px -10px 10px rgba(255, 255, 255, 0.7) inset, 10px 10px 10px rgba(174, 174, 192, 0.2) inset;
}
span {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: calc(max(2.5vw, 2.5vh));
    font-weight: 600;
    color: #354a68;
}
.challenge {
    position: fixed;
    top: 80vh;
    left: calc(min(10vw, 10vh));
    width: calc(min(35vw, 40vh));
    height: calc(min(15vw, 15vh));
}
.bluff {
    position: fixed;
    top: 80vh;
    right: calc(min(10vw, 10vh));
    width: calc(min(35vw, 40vh));
    height: calc(min(15vw, 15vh));
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.25s ease-out;
}
.slide-up-enter-from {
  transform: translateY(30px);
  opacity: 0;
}
.slide-up-leave-to {
  transform: translateY(-30px);
  opacity: 0;
}
.word-move, /* apply transition to moving elements */
.word-enter-active,
.word-leave-active {
  transition: all 0.5s ease;
}

.word-enter-from,
.word-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-leave-active {
  position: absolute;
}
.currentPlayerTime {
    position: fixed;
    top: 5vh;
    left: calc(100% - max(5vw, 5vh));
    font-size: calc(max(2.5vw, 2.5vh));
    font-weight: 600;
    color: #354a68;
}
.indentLeft {
    left: calc(100% - max(20vw, 20vh));
}
.wordFrame {
    width: 100%;
    height: 40%;
    position: fixed;
    left: 0;
    top: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>