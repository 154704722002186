import { defineStore } from "pinia";

export const useResultsStore = defineStore(
    {
        id: 'resultsStore',
        state: () => ({
            p1: '',
            p2: '',
            result: '',
            event: '',
            info: '',
    }),
}
);